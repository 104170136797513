import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const ImageGallery = makeShortcode("ImageGallery");
const ImageGalleryImage = makeShortcode("ImageGalleryImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Our customers and partners`}</p>
    </PageDescription>
    <ImageGallery mdxType="ImageGallery">
      <ImageGalleryImage alt="akaundotcom" title="Akaun Design" col={4} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "500px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe7Gklg//8QAGRABAAMBAQAAAAAAAAAAAAAAAQACEBIT/9oACAEBAAEFAmwT0piDOK5//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAgMAAAAAAAAAAAAAAAAAAAEQITL/2gAIAQEABj8CtmlFoyo//8QAGhABAAMAAwAAAAAAAAAAAAAAAQAQEXHR8f/aAAgBAQABPyFMANm/fSmg8k8Cv//aAAwDAQACAAMAAAAQk8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAEBAQADAQAAAAAAAAAAAAABEQAhMVFx/9oACAEBAAE/EGBIUrLO8Eox8+zDSmCIzgQZgZBnXHBCG//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d0e133013fe2754bbb9fa4811bb28e96/5f1c3/akaun.webp 288w", "/static/d0e133013fe2754bbb9fa4811bb28e96/10636/akaun.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d0e133013fe2754bbb9fa4811bb28e96/58ca1/akaun.jpg 288w", "/static/d0e133013fe2754bbb9fa4811bb28e96/10bbb/akaun.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d0e133013fe2754bbb9fa4811bb28e96/10bbb/akaun.jpg",
              "alt": "akaundotcom",
              "title": "akaundotcom",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="padocx" title="Padocx Exergy" col={4} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "500px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe5NyaA//8QAGRABAAIDAAAAAAAAAAAAAAAAAQIQERIT/9oACAEBAAEFAmQPSNYF0jX/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAXEAADAQAAAAAAAAAAAAAAAAAAEBEx/9oACAEBAAY/Ao6Yv//EABsQAQABBQEAAAAAAAAAAAAAAAEQABExQXGR/9oACAEBAAE/IR6y108hIQXNz//aAAwDAQACAAMAAAAQ98//xAAXEQADAQAAAAAAAAAAAAAAAAABEBFB/9oACAEDAQE/EDMX/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxABAAICAwAAAAAAAAAAAAAAAQARMUEhgZH/2gAIAQEAAT8QcNMHEG33cEQTceGKhZIE3T1gUUT/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4b025ba605a4fb05ab888dccb01ef3bf/5f1c3/padocx.webp 288w", "/static/4b025ba605a4fb05ab888dccb01ef3bf/10636/padocx.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4b025ba605a4fb05ab888dccb01ef3bf/58ca1/padocx.jpg 288w", "/static/4b025ba605a4fb05ab888dccb01ef3bf/10bbb/padocx.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/4b025ba605a4fb05ab888dccb01ef3bf/10bbb/padocx.jpg",
              "alt": "Padocx",
              "title": "Padocx",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="revrides" title="Rev Rides" col={4} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "500px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAe5NhoT/xAAaEAACAgMAAAAAAAAAAAAAAAAAARESECEx/9oACAEBAAEFArbujpCIWP/EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAEDAQE/AYf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAaEAEAAwADAAAAAAAAAAAAAAABABExQWFx/9oACAEBAAE/IUFKYPzXsEFmRTQnUTJ//9oADAMBAAIAAwAAABDoz//EABYRAQEBAAAAAAAAAAAAAAAAABEQMf/aAAgBAwEBPxBGT//EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAECAQE/EKf/xAAcEAEBAQABBQAAAAAAAAAAAAABEQDRITFBUcH/2gAIAQEAAT8QdOh18c63foxhauyZWqfaaHFgBAhv/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/be721bebdfe9e387f3e69883c466bd10/5f1c3/revrides.webp 288w", "/static/be721bebdfe9e387f3e69883c466bd10/10636/revrides.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/be721bebdfe9e387f3e69883c466bd10/58ca1/revrides.jpg 288w", "/static/be721bebdfe9e387f3e69883c466bd10/10bbb/revrides.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/be721bebdfe9e387f3e69883c466bd10/10bbb/revrides.jpg",
              "alt": "revrides",
              "title": "revrides",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="samsung" title="Samsung" col={4} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "500px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHuV0JIH//EABkQAAIDAQAAAAAAAAAAAAAAAAABAhARE//aAAgBAQABBQJySOkKwxV//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAgMBAAAAAAAAAAAAAAAAARAAITEy/9oACAEBAAY/ArM6Dxf/xAAcEAACAQUBAAAAAAAAAAAAAAAAATEQIWFxkfH/2gAIAQEAAT8hf2ls9okaOUjE5T//2gAMAwEAAgADAAAAEOTP/8QAFREBAQAAAAAAAAAAAAAAAAAAECH/2gAIAQMBAT8Qp//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPxARf//EABkQAQEBAQEBAAAAAAAAAAAAAAERACFh8f/aAAgBAQABPxCGXlinNxvHBAI0emTqn0u+Kxwhv//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5601b061ebcd16d6e0f80227507cc40d/5f1c3/samsung.webp 288w", "/static/5601b061ebcd16d6e0f80227507cc40d/10636/samsung.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5601b061ebcd16d6e0f80227507cc40d/58ca1/samsung.jpg 288w", "/static/5601b061ebcd16d6e0f80227507cc40d/10bbb/samsung.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/5601b061ebcd16d6e0f80227507cc40d/10bbb/samsung.jpg",
              "alt": "samsung",
              "title": "samsung",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="bmw" title="BMW" col={4} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "500px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdwyCo6pf//EABoQAQADAAMAAAAAAAAAAAAAAAIAAQMEERL/2gAIAQEAAQUCaoEbduMVoM+P50n/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAgMBAAAAAAAAAAAAAAABAgAQERJBMf/aAAgBAQAGPwLJgVgBt5RU9m7OXblf/8QAGxAAAgIDAQAAAAAAAAAAAAAAAREAURAhQTH/2gAIAQEAAT8hKPIQtMCei8ODVJr8Oglj/9oADAMBAAIAAwAAABC/z//EABYRAAMAAAAAAAAAAAAAAAAAABARIf/aAAgBAwEBPxCsf//EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAECAQE/EIf/xAAcEAEBAAIDAQEAAAAAAAAAAAABESExABBBUXH/2gAIAQEAAT8QQmQP1WHIVw1dyKDzBh96x0raiOxH6PAh4REKIuNsxev/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f95497a47883cb4b810d8c33adcefd86/5f1c3/bmw.webp 288w", "/static/f95497a47883cb4b810d8c33adcefd86/10636/bmw.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f95497a47883cb4b810d8c33adcefd86/58ca1/bmw.jpg 288w", "/static/f95497a47883cb4b810d8c33adcefd86/10bbb/bmw.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f95497a47883cb4b810d8c33adcefd86/10bbb/bmw.jpg",
              "alt": "bmw",
              "title": "bmw",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="ap" title="American Precision" col={4} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "500px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHdjYhjD//EABgQAQADAQAAAAAAAAAAAAAAAAEAAhAR/9oACAEBAAEFAlKwvV3hn//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAAMBAAAAAAAAAAAAAAAAAAAgIeH/2gAIAQEABj8Cpi//xAAbEAEAAQUBAAAAAAAAAAAAAAABEQAQITFhcf/aAAgBAQABPyEydPKUInPVkndAOC3/2gAMAwEAAgADAAAAEKMP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBABAQACAgMAAAAAAAAAAAAAAREAECFBUbHB/9oACAEBAAE/ELmSpwn1ggrUKHzQBAI9OVQj5DX/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/112a53804da8c68aa91b61bb8f8417e8/5f1c3/ap.webp 288w", "/static/112a53804da8c68aa91b61bb8f8417e8/10636/ap.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/112a53804da8c68aa91b61bb8f8417e8/58ca1/ap.jpg 288w", "/static/112a53804da8c68aa91b61bb8f8417e8/10bbb/ap.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/112a53804da8c68aa91b61bb8f8417e8/10bbb/ap.jpg",
              "alt": "ap",
              "title": "ap",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="siemens" title="Siemens" col={4} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "500px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIB/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQMF/9oADAMBAAIQAxAAAAGKhXOwJ//EABkQAAIDAQAAAAAAAAAAAAAAAAABAhAREv/aAAgBAQABBQJRbOJVprr/xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAwEBPwGH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAgMAAAAAAAAAAAAAAAAAEDEAASH/2gAIAQEABj8Cyoix/8QAGxABAAICAwAAAAAAAAAAAAAAAQAREGEhMZH/2gAIAQEAAT8hCuwm/HhqCOlJue4//9oADAMBAAIAAwAAABAcD//EABYRAAMAAAAAAAAAAAAAAAAAAAEQUf/aAAgBAwEBPxAUv//EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAECAQE/EKf/xAAbEAEBAQACAwAAAAAAAAAAAAABABEhMUFR8f/aAAgBAQABPxB2wO0k+GwojEcSDwT0OX1Uqqrq+b//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f8dd9e284f855e92bb392740c6973802/5f1c3/siemens.webp 288w", "/static/f8dd9e284f855e92bb392740c6973802/10636/siemens.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f8dd9e284f855e92bb392740c6973802/58ca1/siemens.jpg 288w", "/static/f8dd9e284f855e92bb392740c6973802/10bbb/siemens.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f8dd9e284f855e92bb392740c6973802/10bbb/siemens.jpg",
              "alt": "siemens",
              "title": "siemens",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
      </ImageGalleryImage>
    </ImageGallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      